import { apiClient } from "@/courses/services/GenericService.js";
import {
  apiClient as apiClientV2,
  formDataAPIClient,
} from "@/courses/services/ApiClientV2.js";
import {
  ASSIGNMENTS_API_V2_URLS,
  assignmentTypeListAPIUrl,
  assignmentProviderCSVImportUrl,
  assignmentProviderUploadUrl,
  ASSIGNMENT_TYPES_API_V2_URLS,
} from "@/courses/urls.js";

export const getAssignmentsRequest = (courseId) => {
  return apiClient.get(ASSIGNMENTS_API_V2_URLS.index.stringify({ courseId }));
};

export const getAssignmentRequest = (courseId, assignmentId) => {
  return apiClientV2.get(
    ASSIGNMENTS_API_V2_URLS.resource.stringify({
      courseId,
      assignmentId,
    }),
  );
};

export const getAssignmentAnalyticsRequest = async (
  courseId,
  assignmentId,
  params = {},
) => {
  const { data } = await apiClientV2.get(
    ASSIGNMENTS_API_V2_URLS.analytics.stringify(
      {
        courseId,
        assignmentId,
      },
      { params },
    ),
  );
  return data;
};

export const getStudentsRequest = async (courseId, assignmentId, params) => {
  const { data } = await apiClientV2.get(
    ASSIGNMENTS_API_V2_URLS.students.stringify({
      courseId,
      assignmentId,
    }),
    { params },
  );
  return data;
};

export const getGroupsRequest = async (courseId, assignmentId, params) => {
  const { data } = await apiClientV2.get(
    ASSIGNMENTS_API_V2_URLS.groups.stringify({
      courseId,
      assignmentId,
    }),
    { params },
  );
  return data;
};

export const deleteAllGradesRequest = (courseId, assignmentId, params) => {
  return apiClientV2.delete(
    ASSIGNMENTS_API_V2_URLS.deleteAllGrades.stringify({
      courseId,
      assignmentId,
    }),
    { params },
  );
};

export const releaseGradesRequest = (courseId, assignmentId, gradeIds) => {
  return apiClientV2.put(
    ASSIGNMENTS_API_V2_URLS.releaseGrades.stringify({
      courseId,
      assignmentId,
    }),
    { gradeIds },
  );
};

export const getAssignmentOptions = async (courseId) => {
  const url = ASSIGNMENTS_API_V2_URLS.options.stringify({
    courseId: courseId,
  });
  const { data } = await apiClientV2.get(url);
  return data;
};

export const getAssignmentTypes = async () => {
  const url = assignmentTypeListAPIUrl.stringify();
  const { data } = await apiClient.get(url);
  return data.data
    .map((row) => row.attributes)
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const getAssignmentTypeRequest = async (courseId, assignmentTypeId) => {
  const url = ASSIGNMENT_TYPES_API_V2_URLS.resource.stringify({
    courseId: courseId,
    assignmentTypeId: assignmentTypeId,
  });
  const { data } = await apiClientV2.get(url);
  return data;
};

export const getAssignmentTypeOptions = async (courseId) => {
  const url = ASSIGNMENT_TYPES_API_V2_URLS.options.stringify({
    courseId: courseId,
  });
  const { data } = await apiClientV2.get(url);
  return data;
};

export const uploadAssignmentCSV = async (courseId, formData) => {
  const url = assignmentProviderUploadUrl.stringify({
    courseId,
    providerName: "csv",
  });
  const { data } = await formDataAPIClient.post(url, formData);
  return data.data.map((row) => row.attributes);
};

export const importAssignmentsFromCSV = async (courseId, assignments) => {
  const url = assignmentProviderCSVImportUrl.stringify({
    courseId,
    providerName: "csv",
  });
  const { data } = await apiClientV2.post(url, {
    assignments: { assignment_attributes: assignments },
  });
  return data;
};
