export const conditionType = {
  assignment: "Assignment",
  assignmentType: "AssignmentType",
  badge: "Badge",
  learningObjective: "LearningObjective",
  course: "Course",
  gradeSchemeElement: "GradeSchemeElement",
};

export const flatpickrDateConfig = {
  allowInput: true,
  altFormat: "D, M d, Y",
  altInput: true,
  dateFormat: "Y-m-d",
  static: true,
  wrap: true,
};

export const flatpickrDateTimeConfig = {
  altInput: true,
  enableTime: true,
  altFormat: "D, M d, Y at h:i K", // ISO Date format 2017-03-04T01:23:43.000Z
  dateFormat: "Z",
  wrap: true,
  defaultHour: 23,
  defaultMinute: 59,
};

export const analyticsPages = {
  students: "studentPage",
  assignments: "assignmentPage",
  assignmentTypes: "assignmentTypePage",
};

export const JOB_TYPES = {
  canvasGradeImport: "canvas_grade_import",
  canvasUserImport: "canvas_user_import",
};

export const JOB_TYPE_DISPLAY_NAMES = {
  [JOB_TYPES.canvasGradeImport]: "Canvas grades import",
  [JOB_TYPES.canvasUserImport]: "Canvas user import",
  formError: "Form Error",
};

export const JOB_STATUS_TYPES = {
  pending: "pending",
  completed: "completed",
  cancelled: "cancelled",
  failed: "failed",
};

export const NOTIFICATION_JOB_STATUS_MAP = {
  completed: "success",
  pending: "warning",
  failed: "error",
  cancelled: "error",
};

export const NOTIFICATION_JOB_TASK_TYPE_MAP = {
  [JOB_TYPES.canvasGradeImport]: "background_job",
  [JOB_TYPES.canvasUserImport]: "background_job",
};

export const NOTIICATION_JOB_TASK_TYPE_DISPLAY = {
  background_job: "Background Job",
};

export const NOTIFICATION_TYPES = {
  banner: "banner",
  toast: "toast",
};

export const NOTIFICATION_STATUS_TYPES = {
  success: "success", // green
  warning: "warning", // yellow
  error: "error", // red
};

export const NOTIFICATION_VISIBILITY_TYPES = {
  hidden: "hidden",
  visible: "visible",
};

export const LEARNING_OBJECTIVE_FLAGGED_VALUE_TYPES = {
  exceedsProficiency: "exceeds_proficiency",
  proficient: "proficient",
  nearingProficiency: "nearing_proficiency",
  notProficient: "not_proficient",
};
export const LEARNING_OBJECTIVE_LEVEL_FLAGGED_VALUE_TYPES = {
  [LEARNING_OBJECTIVE_FLAGGED_VALUE_TYPES.exceedsProficiency]:
    "Exceeds Proficiency",
  [LEARNING_OBJECTIVE_FLAGGED_VALUE_TYPES.proficient]: "Proficient",
  [LEARNING_OBJECTIVE_FLAGGED_VALUE_TYPES.nearingProficiency]:
    "Nearing Proficiency",
  [LEARNING_OBJECTIVE_FLAGGED_VALUE_TYPES.notProficient]: "Not Proficient",
};

export const FORM_MODE_TYPES = {
  new: "new",
  edit: "edit",
  massEdit: "massEdit",
};

export const ASSIGNMENT_TAB_OPTIONS = {
  basics: "Description & Purpose",
  unlocks: "Unlock Conditions",
  details: "Details",
  grading: "Grading",
};
export const ASSIGNMENT_SHOW_TAB_OPTIONS = {
  grades: "Grades",
  details: "Description & Downloads",
  rubric: "Grading Rubric",
  analytics: "Class Analytics",
  objectives: "Learning Objectives",
};
export const SCORE_LEVEL = "Score Levels";
export const RUBRIC = "Rubric";

export const COLOR_BLUE_3 = "#2E70BE";
export const COLOR_BLUE_MEDIUM_2 = "#215089";
export const COLOR_WHITE = "#fff";
export const COLOR_BLUE_RGB = "31, 119, 180";

export const GRADE_SCOPE_INDIVIDUAL = "Individual";
export const GRADE_SCOPE_GROUP = "Group";
export const GRADE_SCOPE_OPTIONS = [GRADE_SCOPE_INDIVIDUAL, GRADE_SCOPE_GROUP];
