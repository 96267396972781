import { apiClient } from "@/courses/services/GenericService";
import { unlocksAPIUrls } from "@/courses/urls.js";

export const requestUnlockConditionsList = async (
  unlockableId,
  unlockableType,
) => {
  const url = unlocksAPIUrls.list.stringify({ unlockableType, unlockableId });
  const { data } = await apiClient.get(url);
  return data;
};

export const requestUnlockKeysList = async (conditionId, conditionType) => {
  const url = unlocksAPIUrls.keysList.stringify({ conditionType, conditionId });
  const { data } = await apiClient.get(url);
  return data;
};

export const requestUnlockConditionCreate = async (unlockConditionParams) => {
  const url = unlocksAPIUrls.create.stringify();
  const { data } = await apiClient.post(url, unlockConditionParams.attributes);
  return data;
};

export const requestUnlockConditionUpdate = async (unlockConditionParams) => {
  const url = unlocksAPIUrls.update.stringify({
    unlockId: unlockConditionParams.id,
  });
  const { data } = await apiClient.put(url, unlockConditionParams.attributes);
  return data;
};

export const requestUnlockConditionDelete = async (unlockId) => {
  const url = unlocksAPIUrls.delete.stringify({ unlockId });
  const response = await apiClient.delete(url);
  return response;
};
